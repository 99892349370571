import React from 'react';

class CybersourceResponse extends React.Component {
    render() {

       return (
            <React.Fragment>
                <p>Thank you for payment.</p>
            </React.Fragment>
       );
    }
}
export default CybersourceResponse;