import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Customcss from './Customcss';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { ContextProvider } from './helpers/MyContext';
console.log('index loaded');
ReactDOM.render(
  
  <React.StrictMode>
    <Customcss />
  </React.StrictMode>,  
  document.getElementById('css')
);
ReactDOM.render(
  
  <React.StrictMode>
  <ContextProvider>
    <App />
    </ContextProvider>
  </React.StrictMode>,  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
reportWebVitals();
