import React, { Component } from "react";

class TermsConditions extends Component {

    render() {
        return (
            <React.Fragment>

                <div className="login-container position-relative signup-form">
                    <div className="container ">
                        <div className="row search_wrap terms_conditions ">
                            <div className="col-md-12" id="content">

                                <h3 className="text-center">DATA PRIVACY POLICY </h3>
                                <p>&nbsp;</p>
                                <h5>KENYA NATIONAL LIBRARY SERVICE (knls) PRIVACY POLICY </h5>

                                <p>knls is committed to maintaining robust privacy protections for its users.  The knls Privacy Policy is designed to help you understand how it collects, uses and safeguards the information you provide and to assist you in making informed decisions when using the knls Services.</p>
                                <p>For purposes of this Agreement, “Site” refers to knls website (www.knls.ac.ke ). “Systems” refers to knls Systems you may interact with. </p>
                                <p>“Service” refers to the knls services accessed via the systems.</p>
                                <p>The terms “we,” “us,” and “our” refer to the Kenya National Library Service. “You” refers to you, as a user of the knls Sites, Systems or Services.  </p>
                                <p>By accessing the knls Sites, Systems or Services, you accept knls Privacy Policy and Terms of Use and you consent to the collection, storage, use and disclosure of your Personal Information as described in this Privacy Policy and other statutory provisions or laws enacted by the parliament of Kenya. </p>
                                <p>&nbsp;</p>
                                <ol type="I">
                                    <li><h5>INFORMATION COLLECTED BY THE knls</h5>
                                        <p>The knls collects “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information may be collected, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated based on the data you submit and number of clicks. Personal Information includes your email, National ID Number/Passport Number/Allien ID, Date of Birth, Income, Employer, Address, Phone Number, Profession, Bank Account, Business Ownership, Property, Citizenship, Source of Income, social media which you submit to knls when using the knls systems. </p>
                                        <ol>
                                            <li>
                                                <h6>Information collected via Technology</h6>
                                                <p>To access knls services, you will be required to register. However, in an effort to improve the quality of the Service, the knls tracks information provided by your browser or by knls software applications when you view or use the Service, such as the website you came from (known as the “referring URL”), the type of browser you use, the device from which you connected to the Service, the time and date of access, and other information that does not personally identify you. The knls tracks this information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser from knls servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables knls to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing the knls services, both on an individual and aggregate basis. </p>
                                                <p>knls may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser. </p>
                                            </li>
                                            <li>
                                                <h6>Information you provide by registering for an account </h6>
                                                <p>In addition to the information provided automatically by your browser when you visit the Site or Systems, you will need to register and create a personal profile. You can create a profile by registering entering your Personal Information and a password. By registering, you are authorizing us to collect, store and use your details in accordance with this Privacy Policy and other statutory provisions or laws enacted by the parliament of Kenya. </p>
                                            </li>
                                        </ol>
                                        <p></p>
                                    </li>
                                    <li><h5>HOW THE knls USES AND SHARES INFORMATION</h5>
                                        <ul>
                                            <li><h6>Personal Information:</h6>
                                                <p>Except as otherwise stated in this Privacy Policy and other statutory provisions or laws enacted by the parliament of Kenya, knls does not sell, trade, rent or otherwise share your Personal Information with third parties without your consent.  </p>
                                                <p>In general, the Personal Information you provide to the knls is used to help in communication. For example, knls use Personal Information to contact taxpayers in response to questions, solicit feedback from taxpayers, provide technical support, and inform taxpayers about tax procedures. </p>
                                            </li>
                                            <li><h6>Non-Personal Information:</h6>
                                                <p>In general, knls uses Non-Personal Information to improve the Service and customize the user experience. The knls also aggregates Non-Personal Information in order to track trends and analyze use patterns on the Systems. </p>
                                                <p>This Privacy Policy does not limit in any way our use or disclosure of Non Personal Information and the knls reserves the right to use and disclose such Non-Personal Information to partners, government agencies and other third parties according to this privacy policy and other statutory provisions or laws enacted by the parliament of Kenya. </p>
                                                <p>In the event we undergo a business transformation such as introduction of a new system, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy and other statutory provisions or laws enacted by the parliament of Kenya, and that any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy and other statutory provisions or laws enacted by the parliament of Kenya. If our information practices change at any time in the future, we will post the policy changes to the Systems. We suggest that you check the Site periodically if you are concerned about how your information is used. </p>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                                <ol>
                                    <li>
                                        <h5>HOW WE PROTECT INFORMATION </h5>
                                        <p>We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password and knls urges you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. By using the knls Systems, Sites and access Services, you acknowledge that you understand and agree to assume these risks. You also accept responsibility not to disclose your PIN and tax information to suspicious individuals or for nonofficial reasons. </p>
                                    </li>
                                    <li>
                                        <h5>YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION</h5>
                                        <p>The knls sends administrative communication to taxpayers, the taxpayer can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional e-mail. Please note that notwithstanding the promotional preferences you indicate by either unsubscribing or opting out in through an email, knls may continue to send you administrative notices. </p>
                                    </li>
                                    <li>
                                        <h5>LINKS TO OTHER WEBSITES </h5>
                                        <p>As part of the Service, knls may provide links to or compatibility with other websites or applications. However, the knls is not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by knls through the Site and the Systems. Therefore, this Privacy Policy does not apply to your use of a third party website accessed by selecting a link on our Site or via our Systems. To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. The knls encourages taxpayers to read the privacy statements of other websites before proceeding to use them. </p>
                                    </li>
                                    <li>
                                        <h5>CHANGES TO knls PRIVACY POLICY</h5>
                                        <p>knls reserves the right to change this policy and Terms of Service at any time.  The knls will notify you of significant changes to the Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on the site. Significant changes will go into effect 30 days following such notification. Non-material changes or clarifications will take effect immediately. You should periodically check the Site and this privacy page for updates. </p>
                                    </li>
                                    <li>
                                        <h5>CONTACT US </h5>
                                        <p>If you have any questions regarding this privacy policy or the practices of this site, please contact knls by sending an email to <a href="mailto:knls@knls.ac.ke"><u>knls@knls.ac.ke</u></a>.</p>
                                    </li>
                                    <li>
                                        <h5>DISCLAIMER</h5>
                                        <p>By submitting data to knls and/or using the knls web site/systems you give your consent that all personal data that you submit may be processed by knls in the manner and for the purposes described in this privacy policy and other statutory provisions or laws enacted by the parliament of Kenya. </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TermsConditions;