import React from 'react';
import ShoppingCart from "../public_pages/ShoppingCart"
class MyCart extends React.Component {
    render() {

       return (
        <ShoppingCart />
       );
    }
}
export default MyCart;