import React from 'react';
class Footer  {

    html(){
        return (
            <React.Fragment>


            </React.Fragment>
        );
    }
}
export default Footer;